import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReceiptsList() {
  // Use toast
  const toast = useToast()

  const refReceiptListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'type', sortable: true },
    { key: 'memID', sortable: true, thStyle: { width: "8%" }, },
    { key: 'member', sortable: true },
    { key: 'dayborn', sortable: true },
    { key: 'amount', sortable: true },
    { key: 'payment', sortable: true },
    { key: 'service', sortable: true },
    { key: 'by', sortable: true },
    { key: 'approved', sortable: true },
    { key: '_receiptDate', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalReceipts = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = ['All', 10, 25, 50, 100]
  const searchQuery = ref('')
  const fromDate = ref('')
  const toDate = ref('')
  const sortBy = ref('receiptDate')
  const isSortDirDesc = ref(true)
  const typeFilter = ref (null)
  const daybornFilter = ref(null)
  const paymentFilter = ref(null)
  const serviceFilter = ref(null)
  const statusFilter = ref(null)
  const monthFilter = ref(null)
  const totalReceiptAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refReceiptListTable.value ? refReceiptListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReceipts.value,
    }
  })

  const refetchData = () => {
    refReceiptListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter, daybornFilter, paymentFilter, serviceFilter, statusFilter, monthFilter, fromDate, toDate], () => {
    refetchData()
  })

  const getReceipts = computed(() => {
   const response =  store.getters['account-receipt/getReceipts']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeFilter.value,
      dayborn: daybornFilter.value,
      payment: paymentFilter.value,
      service: serviceFilter.value,
      status: statusFilter.value,
      month: monthFilter.value,
      from: fromDate.value,
      to: toDate.value,
    })

    const { receipts, total, totalAmount } = response
    totalReceipts.value = total

    // console.log(total)
    totalReceiptAmount.value = totalAmount

    return receipts
  })

  const getAllReceipts = computed(() => {
    const response =  store.getters['account-receipt/getAllReceipts']
 
     const { receipts, total, totalAmount } = response
     totalReceipts.value = total
    //  totalAmount.value = totalAmount
 
     return receipts
   })

  const fetchReceipts = (ctx, callback) => {
    store
      .dispatch('account-receipt/fetchReceipts')
      .then(response => {
        // const { receipts, total } = response.data

        // callback(receipts)
        // totalReceipts.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching receipts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching receipts list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }


  const blankReceiptData = {
    amount: null,
    type: 'offering',
    description: '',
    member: null,
    service: null,
    dayborn: null,
    payment: 'cash',
    paymentDetails: '',
    months: null,
    date: null,
    currency: null,
    famount: null,
  }

  const receiptData = ref(JSON.parse(JSON.stringify(blankReceiptData)))

  const clearReceiptData = () => {
    receiptData.value = JSON.parse(JSON.stringify(blankReceiptData))
  }

  const editReceipt = (receiptEvent) => {
    
    receiptData.value = JSON.parse(JSON.stringify(Object.assign(receiptEvent)))
    // const { receiptData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewReceiptSidebarActive.value = true
    // console.log(receiptEvent)

    receiptData.value = grabDataFromEditReceipt(receiptEvent)
  }

  const grabDataFromEditReceipt = receiptData => {

    const {
      id,
      amount,
      type,
      member,
      service,
      dayborn,
      payment,
      paymentDetails,
      months,
      date,
    } = receiptData

    return {
      id,
      amount,
      type,
      member,
      service,
      dayborn,
      payment,
      paymentDetails,
      months,
      date,
    }
  }

  const sendSmsReceipt = (data) => {

    const message = `Hello @fullname, Receipt for ${resolveTypeVariant(data.type)} Payment(${data.payment}) of ${parseFloat(data.amount).toFixed(2)}, ${data.type=='tithe' ? ' for '+data.months+' months. Payment Received! on '+(new Date().toGMTString().substring(5,16)) : 'Payment Received! on '+(new Date().toGMTString().substring(5,16)) }. Thank you!`

    const smsData = { 
                          contacts: [data.member].map(x=>({ name: x.member, contact: x.contact1 })),
                          variant: "receipt", 
                          message: message,
                          groups: null,
                          scheduled: null
                        }

    // init an sms for tithe and Thanksgivig
    store.dispatch('app-sms/addSms', { sms: smsData }).then(response => {
          // eslint-disable-next-line no-use-before-define
          // fetchSmses()
          // getSmsInfo()
    
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error=> {
        toast({
            component: ToastificationContent,
            props: {
              title: error.response.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
  
          return error
      })
  }

  // ------------------------------------------------
  // addReceipt
  // ------------------------------------------------
  const addReceipt = receiptEvent => {
    store.dispatch('account-receipt/addReceipt', { receipt: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchReceipts()

      // check
      // if (['tithe', 'thanksgiving'].includes(receiptEvent.type)) {

      //   sendSmsReceipt(receiptEvent)

      //   console.log(receiptEvent.type)
      //   console.log(receiptEvent)
      // }

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  // ------------------------------------------------
  // updateReceipt
  // ------------------------------------------------
  const updateReceipt = receiptEvent => {
    
    store.dispatch('account-receipt/updateReceipt', { receipt: receiptEvent }).then(response => {
      
      fetchReceipts()
      // close sidebar
      isAddNewReceiptSidebarActive.value = false

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  const genReceiptReport =  () => {
    //
    store.dispatch('account-receipt/genReceiptReport', {
      q: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeFilter.value,
      dayborn: daybornFilter.value,
      payment: paymentFilter.value,
      service: serviceFilter.value,
      status: statusFilter.value,
      month: monthFilter.value,
    }).then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchReceipts()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }

  const delReceipt = (receiptEvent) => {
    
    store.dispatch('account-receipt/delReceipt', { receipt: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchReceipts()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const cancelReceipt = (receiptEvent) => {
    
    store.dispatch('account-receipt/cancelReceipt', { receipt: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchReceipts()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const isAddNewReceiptSidebarActive = ref(false)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveReceiptPaymentVariant =  payment => {
    if (payment === 'cash') return 'primary'
    if (payment === 'mobile_money') return 'warning'
    if (payment === 'cheque') return 'info'
    return 'primary'
  }

  const resolveReceiptStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'secondary'
  }

  const resolvePaymentVariant = payment => {
    if (payment === 'cash') return 'Cash'
    if (payment === 'mobile_money') return 'Mobile Money'
    if (payment === 'cheque') return 'Cheque'
  }

  const resolveDaybornVariant = dayborn => {
    if (dayborn === 'sunday') return 'Sunday'
    if (dayborn === 'monday') return 'Monday'
    if (dayborn === 'tuesday') return 'Tuesday'
    if (dayborn === 'wednesday') return 'Wednesday'
    if (dayborn === 'thursday') return 'Thursday'
    if (dayborn === 'friday') return 'Friday'
    if (dayborn === 'saturday') return 'Saturday'
  }

  const resolveTypeVariant = type => {
    if (type === 'offering') return 'Offering'
    if (type === 'tithe') return 'Tithe'
    if (type === 'welfare') return 'Welfare'
    if (type === 'welfare dues') return 'Welfare Dues'
    if (type === 'harvest') return 'Harvest'
    if (type === 'harvest pledge') return 'Harvest Pledge'
    if (type === 'special offering') return 'Special Offering'
    if (type === 'thanksgiving') return 'Thanksgiving'
    if (type === 'others') return 'Others'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'offering') return 'ShieldIcon'
    if (role === 'tithe') return 'PocketIcon'
    if (role === 'welfare') return 'UsersIcon'
    if (role === 'welfare dues') return 'CalendarIcon'
    if (role === 'harvest') return 'ArchiveIcon'
    if (role === 'harvest pledge') return 'ThumbsUpIcon'
    if (role === 'special offering') return 'MailIcon'
    if (role === 'thanksgiving') return 'HeartIcon'
    if (role === 'others') return 'CreditCardIcon'
    return ''
  }

  return {
    getReceipts,
    getAllReceipts,
    fetchReceipts,
    fetchMembers,
    tableColumns,
    perPage,
    currentPage,
    totalReceipts,
    dataMeta,
    perPageOptions,
    searchQuery,
    fromDate,
    toDate,
    sortBy,
    isSortDirDesc,
    refReceiptListTable,
    refetchData,
    totalAmount,

    resolveReceiptStatusVariant,
    resolvePaymentVariant,
    resolveReceiptPaymentVariant,
    resolveDaybornVariant,
    resolveTypeVariant,
    resolveTypeRoleIcon,

    //
    isAddNewReceiptSidebarActive,
    clearReceiptData,
    editReceipt,
    delReceipt,
    addReceipt,
    updateReceipt,
    receiptData,
    cancelReceipt,
    genReceiptReport,

    // Extra Filters
    typeFilter,
    daybornFilter,
    paymentFilter,
    serviceFilter,
    statusFilter,
    monthFilter,
    totalReceiptAmount,
  }
}
