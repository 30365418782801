import axios from '@/libs/axios'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import { saveAs } from 'file-saver';
import moment from "moment"

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    payments: [],
    total: 0,
    payment: {},
    categories: [],
    totalCategory: 0,
    category: {}
  },
  getters: {
    getPayments: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'date',
        sortDesc = true,
        status = null,
        type = null,
        category = null,
        month = null,
        payment = null,
        range = null,
        from = '',
        to = ''
      } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.payments.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.amount == queryLowered || pay._paymentDate.toLowerCase().includes(queryLowered) || pay.paymentFor.toLowerCase().includes(queryLowered) 
          ) 
          &&
          (
            (new Date(pay.paymentDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
            && 
            (new Date(pay.paymentDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) )
          )
          &&
          pay.type === (type || pay.type) &&
          pay.category === (category || pay.category) &&
          pay.payment === (payment || pay.payment) &&
          pay.month === (month || pay.month) &&
          pay.paymentDate.includes(getRange(range) || pay.paymentDate) &&
          pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      if (perPage == 'All') {
        var pp = state.payments.length
      } else {
        var pp = perPage
      }

      return {
          payments: paginateArray(sortedData, pp, page),
          total: filteredData.length,
          totalAmount: filteredData.reduce((a, b) => a + b.amount, 0)
      }
      
    },
    getApprovals: state => (params) => {

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'paymentDate',
        sortDesc = true,
        status = null,
        range = null,
        from = '',
        to = ''
      } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      // console.log(getRange(range))

      const queryLowered = q.toLowerCase()
      const filteredData = state.payments.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.amount == queryLowered || pay._paymentDate.toLowerCase().includes(queryLowered) || pay.category.toLowerCase().includes(queryLowered) || pay.type.toLowerCase().includes(queryLowered)
          ) 
          &&
          (
            (new Date(pay.paymentDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
            && 
            (new Date(pay.paymentDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) )
          )
          &&
          pay.paymentDate.includes(getRange(range) || pay.paymentDate) &&
          pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      if (perPage == 'All') {
        var pp = state.payments.length
      } else {
        var pp = perPage
      }

      return {
          approvals: paginateArray(sortedData, pp, page),
          atotal: filteredData.length,
          atotalAmount: filteredData.reduce((a, b) => a + b.amount, 0)
      }
      
    },
    getPaymentCategories: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'category',
        sortDesc = true,
      } = params

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.categories.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.category == queryLowered || pay.description.toLowerCase().includes(queryLowered)  || pay.by.toLowerCase().includes(queryLowered)
          ) 
          // pay.type === (type || pay.type) &&
          // pay.payment === (payment || pay.payment) &&
          // pay.month === (month || pay.month) &&
          // pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      return {
          categories: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
      }
      
    },
    getAllPaymentCategories: state => {
      return {
        categories: state.categories,
        total: state.categories.length,
      }
    },
    getAllPayments: state => {
      return {
        payments: state.payments,
        total: state.payments.length,
        totalAmount: state.payments.reduce((a, b) => a + b.amount, 0),
      }
    },
    getPayment: state => {
      return state.payment
    }
  },
  mutations: {
    updatePayments:  (state, payments) => {
      //
      state.payments = payments
      state.total = payments.length
    },
    updatePayment: (state, payment) => {
      //
      state.payment = payment
    },
    updatePaymentCategories: (state, categories) => {
      //
      state.categories = categories
      state.totalCategory = categories.length
    },
  },
  actions: {
    fetchPayments({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/'+userData.church)
          .then(response => {

            //
            commit('updatePayments', response.data.filter(re => userData.role !=="admin" ? re.by == userData.fullName : true))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updatePayments', [])
            
            reject(error)

          })
      })
    },
    fetchPayment({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payment/${userData.church}/${id}`)
            .then(response => { 

              //
              commit('updatePayment', response.data)
              
              resolve(response) 

            })
            .catch(error => {
              
              // 
              commit('updatePayment', {})

              reject(error)
            
          })
      })
    },
    churchInfo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/${userData.church}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
          })
      })
    },
    addPayment(ctx, { payment }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payment', { ...payment, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePayment(ctx, { payment } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/payment/${userData.church}/${payment.id}`, { ...payment, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approvePayment(ctx, { payment }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/payments/approvals/${userData.church}`, { ...payment, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelPayment(ctx, { payment } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/payment/cancel/${userData.church}/${payment.id}`, { ...payment, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delPayment(ctx, { payment }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/payment/${userData.church}/${payment.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genPaymentReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {

        const fileName = 'Payment_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/payment/report/${userData.church}`, {
            fileName: fileName,
            params: queryParams
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
    // payment category
    fetchPaymentCategories({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/categories/'+userData.church)
          .then(response => {

            //
            commit('updatePaymentCategories', response.data.filter(re => userData.role !=="admin" ? re.by == userData.fullName : true))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updatePayments', [])
            
            reject(error)

          })
      })
    },
    addPaymentCategory(ctx, { paymentCategory }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payment/category', { ...paymentCategory, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentCategory(ctx, { category, paymentCategory } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/payment/category/${userData.church}/${category}/${paymentCategory.id}`, { ...paymentCategory, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delPaymentCategory(ctx, { paymentCategory }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/payment/category/${userData.church}/${userData.role}/${paymentCategory.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
