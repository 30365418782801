<template>
    <b-card
      v-if="data"
      class="earnings-card"
    >
      <b-row>
        <b-col cols="6">
          <b-card-title class="mb-1">
            Male / Female Distribution
          </b-card-title>
          <div class="font-small-2">
            This Year
          </div>
          <h5 class="mb-1">
            {{ parseFloat(total).toLocaleString() }}
          </h5>
          <b-card-text class="text-muted font-small-2">
            <span class="font-weight-bolder">Males : {{ data.series[0] }}</span><span> </span>
            <br>
            <span class="font-weight-bolder">Females : {{ data.series[1] }}</span><span></span>
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <!-- chart -->
          <vue-apex-charts
            height="120"
            :options="membersChart.chartOptions"
            :series="data.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import { kFormatter } from '@core/utils/filter'
  
  const $earningsStrokeColor2 = $themeColors.info
  const $earningsStrokeColor3 = '#ff9f43'
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardTitle,
      BCardText,
      VueApexCharts,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      male: {
        type: Number,
        default: () => 0,
      },
      total: {
        type: Number,
        default: () => 0,
      }
    },
    data(props) {
      return {
        membersChart: {
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            comparedResult: [2, -3],
            labels: ['Male', 'Female'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor2, $earningsStrokeColor3, '#00cfe8'],
            grid: {
              padding: {
                right: -20,
                bottom: -8,
                left: -20,
              },
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: -15,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${ parseFloat((val/props.total * 100).toFixed(2)) }%`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Male',
                      formatter() {
                        return  `${props.male}%`
                      },
                    },
                  },
                },
              },
            },
            responsive: [
              {
                breakpoint: 1325,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
              {
                breakpoint: 1045,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
            ],
          },
        },
      }
    },
  }
  </script>
  