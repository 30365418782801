<template>
    <b-card
      v-if="data"
      class="earnings-card"
    >
      <b-row>
        <b-col cols="6">
          <b-card-title class="mb-1">
            Payments
          </b-card-title>
          <div class="font-small-2">
            This Year
          </div>
          <h5 class="mb-1">
            {{ parseFloat(total).toLocaleString() }}
          </h5>
          <b-card-text class="text-muted font-small-2">
            <span class="font-weight-bolder">{{ month }}</span><span> this month.</span>
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <!-- chart -->
          <vue-apex-charts
            height="120"
            :options="paymentsChart.chartOptions"
            :series="data.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  
  const $earningsStrokeColor2 = '#ea54558f'
  const $earningsStrokeColor3 = $themeColors.danger
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardTitle,
      BCardText,
      VueApexCharts,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      cash: {
        type: Number,
        default: () => 0,
      },
      total: {
        type: Number,
        default: () => 0,
      },
      month: {
        type: Number,
        default: () => 0,
      }
    },
    data(props) {
      return {
        paymentsChart: {
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            comparedResult: [2, -3, 8],
            labels: ['Cash', 'Mobile Money', 'Cheque'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.danger],
            grid: {
              padding: {
                right: -20,
                bottom: -8,
                left: -20,
              },
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: -15,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${ parseFloat((val/props.total * 100).toFixed(2)) }%`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Cash',
                      formatter() {
                        return `${props.cash}%`
                      },
                    },
                  },
                },
              },
            },
            responsive: [
              {
                breakpoint: 1325,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
              {
                breakpoint: 1045,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
            ],
          },
        },
      }
    },
  }
  </script>
  