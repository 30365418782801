import store from '@/store'

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEcommerceList() {
 
  const fetchReceipts = (ctx, callback) => {
    store
      .dispatch('account-receipt/fetchReceipts')
      .then(response => {
        // const { total } = response.data

        // console.log(response.data.length)

        // callback(total)
        // totalReceipts.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching receipts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        // const { members, total } = response.data

        // callback(total)
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching receipts list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }


  const fetchPayments = (ctx, callback) => {
    store
      .dispatch('account-payment/fetchPayments')
      .then(response => {
        // const { payments, total } = response.data

        // callback(payments)
        // totalPayments.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching payments list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchReceipts,
    fetchMembers,
    fetchPayments
   
  }
}
