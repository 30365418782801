import axios from '@/libs/axios'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    members: [],
    total: 0,
    member: {}
  },
  getters: {
    getMembers: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'fullName',
        sortDesc = false,
        membershipType = null,
        gender = null,
        marital = null,
        dayborn = null,
      } = params

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.members.filter(
        member =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              member.fullName.toLowerCase().includes(queryLowered) || member.memID.toLowerCase().includes(queryLowered) || member._dob.toLowerCase().includes(queryLowered) || member._dateDied.toLowerCase().includes(queryLowered)
          ) &&
          member.membershipType === (membershipType || member.membershipType) &&
          member.gender === (gender || member.gender) &&
          member.maritalStatus === (marital || member.maritalStatus) &&
          member.dayborn === (dayborn || member.dayborn),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)

      return {
          members: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
      }
      
    },
    getAllMembers: state => {
      return {
        members: state.members,
        total: state.members.length,
      }
    },
    getMember: state => {
      return state.member
    }
  },
  mutations: {
    updateMembers:  (state, members) => {
      //
      state.members = members
      state.total = members.length
    },
    updateMember: (state, member) => {
      //
      state.member = member
    }
  },
  actions: {
    fetchMembers({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/members/'+userData.church)
          .then(response => {

            //
            commit('updateMembers', response.data)
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateMembers', {})
            
            reject(error)

          })
      })
    },
    fetchMember({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/member/${userData.church}/${id}`)
            .then(response => { 

              //
              commit('updateMember', response.data)
              
              resolve(response) 

            })
            .catch(error => {
              
              // 
              commit('updateMember', {})

              reject(error)
            
          })
      })
    },
    addMember(ctx, memberData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/member', { ...memberData, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMemberPersonal(ctx, { id, memberData } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/member/personal/${id}`, { ...memberData, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMemberAddress(ctx, { id, memberData } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/member/address/${id}`, { ...memberData, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMemberMarital(ctx, { id, memberData } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/member/marital/${id}`, { ...memberData, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMemberChurch(ctx, { id, memberData } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/member/church/${id}`, { ...memberData, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadMemberData(ctx, memberData ) {
      return new Promise((resolve, reject) => {
        const file  = new FormData()
        file.append('file', memberData.file)
        console.log(memberData.file)
        axios
          .post(`/member/upload/${userData.church}`, file, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
