import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMembersList() {
  // Use toast
  const toast = useToast()

  const refMemberListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'fullName', sortable: true },
    { key: 'memID', sortable: true },
    { key: 'contact1', label: 'contact', sortable: false },
    { key: 'dob', sortable: false },
    { key: 'gender', sortable: true },
    { key: 'dayborn', sortable: true },
    // { key: 'role', sortable: true },
    { key: 'membershipType', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalMembers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('fullName')
  const isSortDirDesc = ref(false)
  const daybornFilter = ref(null)
  const genderFilter = ref(null)
  const maritalFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMemberListTable.value ? refMemberListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMembers.value,
    }
  })

  const refetchData = () => {
    refMemberListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, daybornFilter, genderFilter, maritalFilter, statusFilter], () => {
    refetchData()
  })

  const getMembers = computed(() => {
   const response =  store.getters['app-member/getMembers']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      dayborn: daybornFilter.value,
      gender: genderFilter.value,
      marital: maritalFilter.value,
      membershipType: statusFilter.value,
    })

    const { members, total } = response
    totalMembers.value = total

    return members
  })

  const getAllMembers = computed(() => {
    const response =  store.getters['app-member/getAllMembers']
 
     const { members, total } = response
     totalMembers.value = total
 
     return members
   })

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        dayborn: daybornFilter.value,
        gender: genderFilter.value,
        marital: maritalFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { members, total } = response.data

        // callback(members)
        // totalMembers.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching members list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMemberRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveMemberGenderVariant =  gender => {
    if (gender === 'male') return 'primary'
    if (gender === 'female') return 'warning'
    return 'primary'
  }

  const resolveMemberRoleIcon = role => {
    if (role === 'subscriber') return 'MemberIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'MemberIcon'
  }

  const resolveMemberStatusVariant = status => {
    if (status === 'distant') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'not active') return 'secondary'
    if (status === 'invalid') return 'info'
    if (status === 'deceased') return 'danger'
    return 'primary'
  }

  const resolveGenderVariant = gender => {
    if (gender === 'male') return 'Male'
    if (gender === 'female') return 'Female'
  }

  const resolveDaybornVariant = gender => {
    if (gender === 'sunday') return 'Sunday'
    if (gender === 'monday') return 'Monday'
    if (gender === 'tuesday') return 'Tuesday'
    if (gender === 'wednesday') return 'Wednesday'
    if (gender === 'thursday') return 'Thursday'
    if (gender === 'friday') return 'Friday'
    if (gender === 'saturday') return 'Saturday'
  }

  return {
    getMembers,
    getAllMembers,
    fetchMembers,
    tableColumns,
    perPage,
    currentPage,
    totalMembers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMemberListTable,
    refetchData,

    resolveMemberRoleVariant,
    resolveMemberRoleIcon,
    resolveMemberStatusVariant,
    resolveGenderVariant,
    resolveMemberGenderVariant,
    resolveDaybornVariant,

    // Extra Filters
    daybornFilter,
    genderFilter,
    maritalFilter,
    statusFilter,
  }
}
