<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <!-- <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col> -->
          <!--/ Bar Chart - Orders -->
          <!-- <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col> -->
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-members-chart :data="data.membersChart" :total="totalMembers" :male="totalMale" />
          </b-col>

          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" :total="totalEarn" :cash="Earncash" :month="EarnMonth" />
          </b-col>

          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-payments-chart :data="data.paymentsChart" :total="totalPay" :cash="Paycash" :month="PayMonth"/>
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row v-if="true" class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import axios from 'axios'
import moment  from 'moment';

import { onUnmounted,onMounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceMembersChart from './EcommerceMembersChart.vue'
import EcommercePaymentsChart from './EcommercePaymentsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

import useEcommerceList from './useEcommerceList'

import store from '@/store'
import receiptStoreModule from '../../accounts/receipt/receiptStoreModule'
import useReceiptList from '../../accounts/receipt/receipt-list/useReceiptList'

import memberStoreModule from '../../apps/membership/memberStoreModule'
import useMembersList from '../../apps/membership/members-list/useMemberList'

import paymentStoreModule from '../../accounts/payment/paymentStoreModule'
import usePaymentList from '../../accounts/payment/payment-list/usePaymentList'

import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommercePaymentsChart,
    EcommerceMembersChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      // data: {},
    }
  },
  setup() {

    const data = {
      congratulations: {
        name: 'John',
        saleToday: '48900',
      },
      statisticsItems: [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Members',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Receipts',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Payments',
          customClass: '',
        },
        {
          icon: 'LayersIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Balance',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      statisticsOrder: {
        series: [
          {
            name: '2020',
            data: [45, 85, 65, 45, 65],
          },
        ],
      },
      statisticsProfit: {
        series: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
      },
      membersChart: {
        series: [53, 16],
      },
      earningsChart: {
        series: [53, 16, 31],
      },
      paymentsChart: {
        series: [10, 5, 16],
      },
      revenue: {
        years: ['2020', '2019', '2018'],
        price: '25,852',
        budget: '56,800',
        revenueReport: {
          series: [
            {
              name: 'Earning',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Expense',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        budgetChart: {
          series: [
            {
              name: 'Budget',
              data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
            },
            {
              name: 'Revenue',
              data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
            },
          ],
        },
      },
      companyTable: [
        {
          avatarImg: require('@/assets/images/icons/toolbox.svg'),
          title: 'Dixons',
          subtitle: 'meguc@ruj.io',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          viewTitle: '23.4k',
          viewsub: 'in 24 hours',
          revenue: '891.2',
          sales: '68',
          loss: true,
        },
        {
          avatarImg: require('@/assets/images/icons/parachute.svg'),
          title: 'Motels',
          subtitle: 'vecav@hodzi.co.uk',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          viewTitle: '78k',
          viewsub: 'in 2 days',
          revenue: '668.51',
          sales: '97',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/brush.svg'),
          title: 'Zipcar',
          subtitle: 'davcilse@is.gov',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '162',
          viewsub: 'in 5 days',
          revenue: '522.29',
          sales: '62',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/star.svg'),
          title: 'Owning',
          subtitle: 'us@cuhil.gov',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          viewTitle: '214',
          viewsub: 'in 24 hours',
          revenue: '291.01',
          sales: '88',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/book.svg'),
          title: 'Cafés',
          subtitle: 'pudais@jife.com',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          viewTitle: '208',
          viewsub: 'in 1 week',
          revenue: '783.93',
          sales: '16',
          loss: true,
        },
        {
          avatarImg: require('@/assets/images/icons/rocket.svg'),
          title: 'Kmart',
          subtitle: 'bipri@cawiw.com',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '990',
          viewsub: 'in 1 month',
          revenue: '780.05',
          sales: '78',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/speaker.svg'),
          title: 'Payers',
          subtitle: 'luk@izug.io',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '12.9k',
          viewsub: 'in 12 hours',
          revenue: '531.49',
          sales: '42',
          loss: false,
        },
      ],
      meetup: {
        mediaData: [
          { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
          { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
        ],
        avatars: [
          { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
          { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
        ],
      },
      goalOverview: {
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      transactionData: [
        {
          mode: 'Wallet',
          types: 'Starbucks',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          payment: '-$74',
          deduction: true,
        },
        {
          mode: 'Bank Transfer',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Mastercard',
          types: 'Ordered Food',
          avatar: 'CreditCardIcon',
          avatarVariant: 'light-warning',
          payment: '-$23',
          deduction: true,
        },
        {
          mode: 'Transfer',
          types: 'Refund',
          avatar: 'TrendingUpIcon',
          avatarVariant: 'light-info',
          payment: '+$98',
          deduction: false,
        },
      ],
    }
    
    //
    const userData = getUserData()
    data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    data.congratulations.church = userData.church

    //
    const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-receipt'
    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
    const ACCOUNT_PAYMENT_STORE_MODULE_NAME = 'account-payment'

    // Register module
    if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
    if (!store.hasModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME, paymentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
      if (store.hasModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)
    })

    const { fetchMembers, fetchReceipts, fetchPayments } = useEcommerceList()

    fetchMembers()
    fetchReceipts()
    fetchPayments()

    // const { getMembers } = useMembersList()
    // const { getReceipts, totalReceiptAmount } = useReceiptList()
    // const { getPayments, totalPaymentAmount } = usePaymentList()
    

    let Earncash = 0;
    let Paycash = 0;
    let totalEarn = 0;
    let totalPay = 0;
    let EarnMonth = 0;
    let PayMonth = 0;
    let totalMembers = 0;
    let totalMale = 0;
    
    // call computed
    // getMembers.value
    // getReceipts.value
    // getPayments.value
    // onMounted(() => {

    const allMembers = store.getters[MEMBER_APP_STORE_MODULE_NAME+"/getAllMembers"]
    const allReceipts = store.getters[ACCOUNT_RECEIPT_STORE_MODULE_NAME+"/getAllReceipts"]
    const allPayments = store.getters[ACCOUNT_PAYMENT_STORE_MODULE_NAME+"/getAllPayments"]

    
    const allr = allReceipts.receipts.filter(r=>r.status == 'approved').reduce((a, b) => a + b.amount, 0)
    const allp = allPayments.payments.filter(r=>r.status == 'approved').reduce((a, b) => a + b.amount, 0)

    // statistics
    data.statisticsItems[0].title = allMembers.total
    data.statisticsItems[1].title = kFormatter(allr)
    data.statisticsItems[2].title = kFormatter(allp)
    data.statisticsItems[3].title = kFormatter((allr - allp))

    // receipts - earnings
    const pay = ['cash', 'mobile_money', 'cheque']
    

    totalMembers = allMembers.total

    // data.earningsChart.series = []

    //
    const gender = ["male", "female"]

    gender.forEach((v,i)=> {
      //
      const g = allMembers.members.filter(m=>m.gender == v).length
      //
      data.membersChart.series[i] = g
    })
    
    totalMale = parseFloat((data.membersChart.series[0]/totalMembers * 100).toFixed(2))

    pay.forEach((v,i)=> {

      //
      let tot = allReceipts.receipts.filter(r=>r.payment == v && r.status == 'approved').reduce((a, b) => a + b.amount, 0)
      let tot2 = allPayments.payments.filter(p=>p.payment == v && p.status == 'approved').reduce((a, b) => a + b.amount, 0)
      data.earningsChart.series[i] = parseFloat(tot.toFixed(2))
      data.paymentsChart.series[i] = parseFloat(tot2.toFixed(2))

    })

    totalEarn = data.earningsChart.series.reduce((a, b) => a + b, 0)
    totalPay = data.paymentsChart.series.reduce((a, b) => a + b, 0)
    Earncash = parseFloat((data.earningsChart.series[0]/totalEarn * 100).toFixed(2))
    Paycash = parseFloat((data.paymentsChart.series[0]/totalPay * 100).toFixed(2))
    EarnMonth = allReceipts.receipts.filter(r=> r.receiptDate.includes( new Date().toISOString().substring(0,7) ) && r.status == 'approved' ).reduce((a, b) => a + b.amount, 0)
    PayMonth = allPayments.payments.filter(p=> p.paymentDate.includes(new Date().toISOString().substring(0,7) ) && p.status == 'approved' ).reduce((a, b) => a + b.amount, 0)

    console.log(PayMonth)

    // matrix graph
    const getYear = (year) => {

      var firstmonth = year ? year+"-01-01" :  new Date().getFullYear()+"-01-01"
      // var lastday = new Date(curr.setDate(last)).toDateString().substring(4);

      let months =[]
      //
      for (let index = 0; index < 12; index++) {

        const d = moment(firstmonth).add(index, 'months').toDate().toISOString().substring(0,10)
        
        months.push(d)
      }

      // console.log(days)
      return months

    }

    const receipts = [];
    const payments = [];

    getYear().forEach(month => {
        //
        receipts.push(allReceipts.receipts.filter(r=> r.receiptDate.includes(month.substring(0,7))  && r.status == 'approved').reduce((a, b) => a + b.amount, 0))
        let pay = allPayments.payments.filter(p=> p.date.includes(month.substring(0,7)) && p.status == 'approved').reduce((a, b) => a + b.amount, 0)
        payments.push(pay > 0 ? (-1 * pay) : 0)
          
    });

    data.revenue.revenueReport.series[0].data = receipts
    data.revenue.revenueReport.series[1].data = payments



    console.log(payments)

  // })

    // data.earningsChart = 

    // 
    // const mem  = totalMembers.value;
    // const rec  = ;
    // const pay  = ;
    

    // onCreated(() => {
      // data
      // axios.get('/ecommerce/data')
      // .then(response => {
        // data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        
        
      // })
    // })

    
    // console.log(fetchMembers())

    return {
      data,
      Earncash,
      totalEarn,
      EarnMonth,
      Paycash,
      totalPay,
      PayMonth,
      totalMembers,
      totalMale,
    }
    
  },
  // setup() {
    
  // }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
