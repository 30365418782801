import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentsList() {
  // Use toast
  const toast = useToast()

  const refPaymentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'type', sortable: true },
    // { key: 'memID', sortable: true },
    // { key: 'member', sortable: true },
    { key: 'category', sortable: true },
    // { key: 'requestby', label: 'by', sortable: true },
    { key: 'amount', sortable: true },
    { key: 'payment', sortable: true },
    { key: 'by', sortable: true },
    { key: '_paymentDate', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalPayments = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = ['All', 10, 25, 50, 100]
  const searchQuery = ref('')
  const fromDate = ref('')
  const toDate = ref('')
  const sortBy = ref('paymentDate')
  const isSortDirDesc = ref(true)
  const typeFilter = ref (null)
  const paymentFilter = ref(null)
  const statusFilter = ref(null)
  const monthFilter = ref(null)
  const categoryFilter = ref(null)
  const rangeFilter = ref(null)
  const totalPaymentAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayments.value,
    }
  })

  const refetchData = () => {
    refPaymentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter, categoryFilter, rangeFilter, paymentFilter, statusFilter, monthFilter, fromDate, toDate], () => {
    refetchData()
  })

  const getPayments = computed(() => {
   const response =  store.getters['account-payment/getPayments']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeFilter.value,
      category: categoryFilter.value,
      payment: paymentFilter.value,
      status: statusFilter.value,
      month: monthFilter.value,
      range: rangeFilter.value,
      from: fromDate.value,
      to: toDate.value,
    })

    const { payments, total, totalAmount } = response
    totalPayments.value = total

    // console.log(total)
    totalPaymentAmount.value = totalAmount

    return payments
  })

  const getAllPayments = computed(() => {
    const response =  store.getters['account-payment/getAllPayments']
 
     const { payments, total, totalAmount } = response
     totalPayments.value = total
    //  totalAmount.value = totalAmount
 
     return payments
   })

  const fetchPayments = (ctx, callback) => {
    store
      .dispatch('account-payment/fetchPayments')
      .then(response => {
        // const { payments, total } = response.data

        // callback(payments)
        // totalPayments.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching payments list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching payments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const fetchGroups = (ctx, callback) => {
    store
      .dispatch('calendar/fetchGroups')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching payments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const getAllPaymentCategories = computed(() => {
    const response =  store.getters['account-payment/getAllPaymentCategories']

    const { categories, total } = response

    return categories.map(c=> ({ label: c.category, value: c.category, }))
  })
  
  const fetchPaymentCategories = (ctx, callback) => {
    store
      .dispatch('account-payment/fetchPaymentCategories')
      .then(() => {
        // const { PaymentCategories, total } = response.data

        // callback(PaymentCategories)
        
        // totalPaymentCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching PaymentCategories' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  const blankPaymentData = {
    amount: null,
    type: 'expense',
    category: null,
    member: null,
    groups: [],
    // requestby: '',
    payment: 'cash',
    paymentFor: '',
    date: null,
  }

  const paymentData = ref(JSON.parse(JSON.stringify(blankPaymentData)))

  const clearPaymentData = () => {
    paymentData.value = JSON.parse(JSON.stringify(blankPaymentData))
  }

  const editPayment = (paymentEvent) => {
    
    paymentData.value = JSON.parse(JSON.stringify(Object.assign(paymentEvent)))
    // const { paymentData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewPaymentSidebarActive.value = true
    // console.log(paymentEvent)

    paymentData.value = grabDataFromEditPayment(paymentEvent)
  }

  const grabDataFromEditPayment = paymentData => {

    const {
      id,
      amount,
      type,
      category,
      payment,
      paymentFor,
      date,
    } = paymentData

    return {
      id,
      amount,
      type,
      category,
      payment,
      paymentFor,
      date,
    }
  }

  // ------------------------------------------------
  // addPayment
  // ------------------------------------------------
  const addPayment = paymentEvent => {
    store.dispatch('account-payment/addPayment', { payment: paymentEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  // ------------------------------------------------
  // updatePayment
  // ------------------------------------------------
  const updatePayment = paymentEvent => {
    
    store.dispatch('account-payment/updatePayment', { payment: paymentEvent }).then(response => {
      
      fetchPayments()
      // close sidebar
      isAddNewPaymentSidebarActive.value = false

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  const genPaymentReport =  () => {
    //
    store.dispatch('account-payment/genPaymentReport', {
      q: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeFilter.value,
      category: categoryFilter.value,
      payment: paymentFilter.value,
      status: statusFilter.value,
      month: monthFilter.value,
      range: rangeFilter.value,
    }).then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchPayments()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }

  const delPayment = (paymentEvent) => {
    
    store.dispatch('account-payment/delPayment', { payment: paymentEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const approvePayment = receiptEvent => {
    store.dispatch('account-payment/approvePayment', { payment: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const cancelPayment = (paymentEvent) => {
    
    store.dispatch('account-payment/cancelPayment', { payment: paymentEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const isAddNewPaymentSidebarActive = ref(false)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePaymentPaymentVariant =  payment => {
    if (payment === 'cash') return 'primary'
    if (payment === 'mobile_money') return 'warning'
    if (payment === 'cheque') return 'info'
    return 'primary'
  }

  const resolvePaymentStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'secondary'
  }

  const resolvePaymentVariant = payment => {
    if (payment === 'cash') return 'Cash'
    if (payment === 'mobile_money') return 'Mobile Money'
    if (payment === 'cheque') return 'Cheque'
  }

  const resolveTypeVariant = type => {
    if (type === 'expense') return 'Expense'
    // if (type === 'water') return 'Water'
    // if (type === 'fuel') return 'Fuel'
    // if (type === 'cableTv') return 'CableTv'
    // if (type === 'gas') return 'Gas'
    // if (type === 'internet') return 'Internet'
    // if (type === 'trash') return 'Trash'
    // if (type === 'phone') return 'Phone'
    // if (type === 'welfare') return 'Welfare'
    // if (type === 'donations') return 'Donations'
    // if (type === 'salary') return 'Salary'
    // if (type === 'project') return 'Project'
    // if (type === 'miscellaneous') return 'Miscellaneous'
    // if (type === 'others') return 'Others'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'expense') return 'ActivityIcon'
    // if (role === 'fuel') return 'AnchorIcon'
    // if (role === 'cableTv') return 'TvIcon'
    // if (role === 'gas') return 'BoxIcon'
    // if (role === 'internet') return 'GlobeIcon'
    // if (role === 'trash') return 'Trash2Icon'
    // if (role === 'phone') return 'PhoneIcon'
    // if (role === 'welfare') return 'UsersIcon'
    // if (role === 'donations') return 'CreditCardIcon'
    // if (role === 'salary') return 'PocketIcon'
    // if (role === 'project') return 'FolderIcon'
    // if (role === 'miscellaneous') return 'FileIcon'
    // if (role === 'others') return 'PackageIcon'
    return ''
  }

  return {
    getPayments,
    getAllPayments,
    fetchPayments,
    fetchMembers,
    fetchGroups,
    tableColumns,
    perPage,
    currentPage,
    totalPayments,
    dataMeta,
    perPageOptions,
    searchQuery,
    fromDate,
    toDate,
    sortBy,
    isSortDirDesc,
    refPaymentListTable,
    refetchData,
    totalAmount,

    resolvePaymentStatusVariant,
    resolvePaymentVariant,
    resolvePaymentPaymentVariant,
    resolveTypeVariant,
    resolveTypeRoleIcon,

    //
    isAddNewPaymentSidebarActive,
    clearPaymentData,
    editPayment,
    delPayment,
    addPayment,
    updatePayment,
    approvePayment,
    paymentData,
    cancelPayment,
    genPaymentReport,

    // Extra Filters
    typeFilter,
    paymentFilter,
    statusFilter,
    monthFilter,
    totalPaymentAmount,
    getAllPaymentCategories,
    fetchPaymentCategories,
    categoryFilter,
    rangeFilter
  }
}
