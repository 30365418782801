import axios from '@/libs/axios'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import { saveAs } from 'file-saver';
import moment from "moment"

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    receipts: [],
    approvals: [],
    total: 0,
    atotal: 0,
    receipt: {}
  },
  getters: {
    getReceipts: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'date',
        sortDesc = true,
        status = null,
        service = null,
        payment = null,
        dayborn = null,
        type = null,
        month = null,
        from = '',
        to = '',
      } = params

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.receipts.filter(
        receipt =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              receipt.amount == queryLowered || receipt.memID.toLowerCase().includes(queryLowered) || receipt._member.toLowerCase().includes(queryLowered) || receipt._receiptDate.toLowerCase().includes(queryLowered) || receipt.paymentDetails.toLowerCase().includes(queryLowered) || receipt.by.toLowerCase().includes(queryLowered)
              || new Date(receipt.date).toGMTString().substring(0,16).toLowerCase().includes(queryLowered)
          ) 
          &&
          (
            (new Date(receipt.receiptDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
            && 
            (new Date(receipt.receiptDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) )
          )
          &&
          receipt.type === (type || receipt.type) &&
          receipt.dayborn === (dayborn || receipt.dayborn) &&
          receipt.payment === (payment || receipt.payment) &&
          receipt.service === (service || receipt.service) &&
          receipt.month === (month || receipt.month) &&
          receipt.status === (status || receipt.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      if (perPage == 'All') {
        var pp = state.receipts.length
      } else {
        var pp = perPage
      }

      return {
          receipts: paginateArray(sortedData, pp, page),
          total: filteredData.length,
          totalAmount: filteredData.reduce((a, b) => a + b.amount, 0)
      }
      
    },
    getApprovals: state => (params) => {

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'receiptDate',
        sortDesc = true,
        status = null,
        range = null,
        from = '',
        to = ''
      } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      // console.log(getRange(range))

      const queryLowered = q.toLowerCase()
      const filteredData = state.approvals.filter(
        receipt =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              receipt.totalAmount == queryLowered || receipt._receiptDate.toLowerCase().includes(queryLowered)
          ) 
          &&
          (
            (new Date(receipt.receiptDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
            && 
            (new Date(receipt.receiptDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) )
          )
          &&
          receipt.receiptDate.includes(getRange(range) || receipt.receiptDate) &&
          receipt.status === (status || receipt.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))
      if (perPage == 'All') {
        var pp = state.approvals.length
      } else {
        var pp = perPage
      }

      return {
          approvals: paginateArray(sortedData, pp, page),
          atotal: filteredData.length,
          atotalAmount: filteredData.reduce((a, b) => a + b.totalAmount, 0)
      }
      
    },
    getAllReceipts: state => {
      return {
        receipts: state.receipts,
        total: state.receipts.length,
        totalAmount: state.receipts.reduce((a, b) => a + b.amount, 0),
      }
    },
    getReceipt: state => {
      return state.receipt
    }
  },
  mutations: {
    updateApprovals:  (state, approvals) => {
      //
      state.approvals = approvals
      state.atotal = approvals.length
    },
    updateReceipts:  (state, receipts) => {
      //
      state.receipts = receipts
      state.total = receipts.length
    },
    updateReceipt: (state, receipt) => {
      //
      state.receipt = receipt
    }
  },
  actions: {
    fetchReceipts({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/receipts/'+userData.church)
          .then(response => {

            //
            commit('updateReceipts', response.data.filter(re=> userData.role !=="admin" ? re.by == userData.fullName : true ))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateReceipts', [])
            
            reject(error)

          })
      })
    },
    fetchApprovals({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/receipts/approvals/'+userData.church)
          .then(response => {

            //
            commit('updateApprovals', response.data.map(app => (
              { type: app._id.type,
                receiptDate: app._id.receiptDate,
                _receiptDate: new Date(app._id.receiptDate).toGMTString().substring(5,16),
                status: app._id.status,
                count: app.count, 
                totalAmount: app.totalAmount,
              })))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateApprovals', [])
            
            reject(error)

          })
      })
    },
    fetchReceipt({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/receipt/${userData.church}/${id}`)
            .then(response => { 

              //
              commit('updateReceipt', response.data)
              
              resolve(response) 

            })
            .catch(error => {
              
              // 
              commit('updateReceipt', {})

              reject(error)
            
          })
      })
    },
    churchInfo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/${userData.church}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
          })
      })
    },
    addReceipt(ctx, { receipt }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/receipt', { ...receipt, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReceipt(ctx, { receipt } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/receipt/${userData.church}/${receipt.id}`, { ...receipt, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveReceipts(ctx, { receipt }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/receipts/approvals/${userData.church}`, { ...receipt, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelReceipt(ctx, { receipt } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/receipt/cancel/${userData.church}/${receipt.id}`, { ...receipt, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delReceipt(ctx, { receipt }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/receipt/${userData.church}/${userData.role}/${receipt.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genReceiptReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {

        const fileName = 'Receipt_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/receipt/report/${userData.church}`, {
            fileName: fileName,
            params: queryParams
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
  },
}
